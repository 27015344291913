import Comment from "./Comment";

export default function Comments(props) {
  const comments = props.comments;
  const survey_id = props.survey_id;
  const survey_type = props.survey_type;

  return (
    <div id="comments" className="timeline">
      {comments.map((item) => (
        <Comment
          comment={item}
          key={item.id}
          survey_id={survey_id}
          survey_type={survey_type}
        />
      ))}
    </div>
  );
}
