import React, { useState } from "react";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import api from "@services/api";
import { storeSession, checkRequiredFields } from "@services/utils";
export default function Login() {
  //LANGUAGE
  const { t, i18n } = useTranslation();
  const onChangeLanguage = (e) => {
    i18n.changeLanguage("fr");
  };
  const onChangeLanguage1 = (e) => {
    i18n.changeLanguage("en");
  };

  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  let count = 0;

  //Appel de la fonction login
  const resetPassword = () => {
    window.location.href = "/password_reset";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await api.login({
      username,
      password,
    })

    if (response.data.access !== undefined) {
      storeSession(response, "supplier");
      count = await checkRequiredFields(response.data.supplier_id);
      let sw_msg;
      let location;

      if (count === 0) {
        location = "/dashboard";
        sw_msg = {
          text: `${t("login.success")}`,
          timer: 3000,
          button: false,
        };
      } else {
        sw_msg = {
          text: `${t("alert.fillprofile")}`,
          timer: 7000,
          button: false,
        };
        location = "/profile";
      }

      swal(sw_msg).then(() => {
        window.location.href = location;
      });
    } else {
      swal({
        text: `${t("login.failed")}`,
        icon: "error",
      });
    }
  };

  return (
    <section className="vh-100" id="connexion">
      <div className="d-flex align-items-center h-100">
        <div className="container h-100 p-5">
          <div
            className="row d-flex  align-items-center h-100 p-2"
            id="container">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6" id="divauth1">
              <a className="small_logo" href="/">
                <img
                  src="/img/logos/logo_ROBERTET_GROUPE_BLANC.png"
                  id="logoauth"
                  alt="logo"
                />
              </a>
            </div>

            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{ borderRadius: 5 }}>
                <div className="lang-menu">
                  <a href="#fr">
                    <img
                      src="/img/flags/fr_flag.png"
                      className="flagdimension"
                      onClick={onChangeLanguage}
                      alt=""
                    />
                  </a>
                  <a href="#en">
                    <img
                      src="/img/flags/US_flag.png"
                      className="flagdimension"
                      onClick={onChangeLanguage1}
                      alt=""
                    />
                  </a>
                </div>

                <div className="card-body p-5">
                  <h2
                    className="text-uppercase text-center mb-10"
                    id="titreprincipal">
                    {t("login.connexion")}
                  </h2>

                  <form noValidate onSubmit={handleSubmit} id="formLogin">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="login">
                        <i className="fas fa fa-user"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        style={{ border: "solid 2px #FFA500" }}
                        placeholder={t("login.email")}
                        id="login"
                        aria-describedby="login"
                        onChange={(e) => setUserName(e.target.value)}
                      />
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="password">
                        <i className="fas fa fa-key"></i>
                      </span>
                      <input
                        type="password"
                        className="form-control"
                        placeholder={t("login.password")}
                        aria-describedby="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="row pt-3">
                      <div className="col-6">
                        <span
                          className="button secondaryAction btn"
                          onClick={() => resetPassword()}>
                          {t("login.forgot")}
                        </span>
                      </div>
                      <div className="col-6  text-end">
                        <div className="form-group">
                          <input
                            type="submit"
                            value={t("login.signin")}
                            className="btn btn-success"
                            style={{
                              background: "rgba(0, 44, 82, 1)",
                              marginLeft: "auto",
                              width: "auto",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
