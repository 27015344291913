import React, { Component } from "react";
import "./App.css";
import Login from "@components/auth/Login";
import ResetPassword from "@components/auth/ResetPassword";
import ConfirmPassword from "@components/auth/confirmPassword";
import { Routes, Route, Navigate } from "react-router-dom";
import Notfound from "@components/Notfound";
import SurveyRawMaterial from "./components/rawmaterial/SurveyRawMaterial";
import Dashboard from "@components/dashboard/Dashboard";
import Surveys from "@components/rawmaterial/Surveys";
import SurveySupplier from "@components/supplier/SurveySupplier";
import UpdateSupplier from "@components/supplier/crud/UpdateSupplier";
import * as Survey from "survey-core";
import "survey-core/survey.i18n";
import $ from "jquery";
import { checkAuth } from "@services/utils";
import "bootstrap/dist/css/bootstrap.min.css";

// https://www.robinwieruch.de/react-router-private-routes/
// https://stackoverflow.com/questions/66289122/how-to-create-a-protected-route
//https://codesandbox.io/s/react-router-v6-security-gojb0?file=/src/App.js:945-996

// https://webera.blog/implement-refresh-token-with-jwt-in-react-app-using-axios-1910087c3d7

// https://medium.com/swlh/authentication-using-jwt-and-refresh-token-part-2-a86150d25152
import * as widgets from "surveyjs-widgets";

window["$"] = window["jQuery"] = $;
require("jquery-ui/ui/widgets/datepicker.js");
widgets.select2(Survey, $);
widgets.select2tagbox(Survey, $);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute path="/dashboard">
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/surveys"
            element={
              <ProtectedRoute path="/surveys">
                <Surveys />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/supplier_survey"
            element={
              <ProtectedRoute path="/supplier_survey">
                <SurveySupplier />
              </ProtectedRoute>
            }
          />
          <Route exact path="/*" element={<Notfound />} />
          <Route
            exact
            path="/profile"
            element={
              <ProtectedRoute path="/profile">
                <UpdateSupplier />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/material_survey"
            element={
              <ProtectedRoute path="/material_survey">
                <SurveyRawMaterial />
              </ProtectedRoute>
            }
          />

          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/password_reset" element={<ResetPassword />} />
          <Route path="/password_confirm" element={<ConfirmPassword />} />
        </Routes>
      </>
    );
  }
}
/**
 * If the user is logged in and has completed their profile, render the children. If the user is logged
 * in and has not completed their profile, redirect them to the profile page. If the user is not logged
 * in, redirect them to the login page
 * @param props - This is the props that are passed to the component.
 * @returns The component is being returned.
 */

const ProtectedRoute = (props) => {
  const [auth, profile_complete] = checkAuth();
  if (
    (auth === true && profile_complete === "true") ||
    (auth === true && profile_complete === "false" && props.path === "/profile")
  ) {
    return props.children;
  }
  if (
    auth === true &&
    profile_complete === "false" &&
    props.path !== "/profile"
  ) {
    return <Navigate to="/profile" />;
  }
  return <Navigate to="/login" />;
};

export default App;
