import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "@services/api";
import { useLocation } from "react-router-dom";
import swal from "sweetalert";

export default function ConfirmPassword(props) {
  //LANGUAGE
  const { t, i18n } = useTranslation();
  const onChangeLanguage = (e) => {
    i18n.changeLanguage("fr");
  };
  const onChangeLanguage1 = (e) => {
    i18n.changeLanguage("en");
  };
  const [password, setPassword] = useState();
  const [password_confirm, setPasswordConfirm] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (!password || !password_confirm) {
      swal({ text: `${t("confirm.fillpassword")}` });
      isValid = false;
    }

    if (password !== password_confirm) {
      swal({ text: `${t("confirm.notmatch")}` });
      isValid = false;
    }

    const token = params.get("token");
    if (isValid) {
      const response = await api.password_confirm({
        token,
        password,
      });
      if (response.data.status === "OK") {
        swal({
          text: `${t("confirm.success")}`,
          timer: 7000,
          button: false,
        }).then(() => {
          window.location.href = "/";
        });
      } else {
        console.log(response);
      }
    }
  };

  return (
    <section className="vh-100" id="connexion">
      <div className="d-flex align-items-center h-100">
        <div className="container h-100 p-5">
          <div
            className="row d-flex  align-items-center h-100 p-2"
            id="container">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6" id="divauth1">
              <a className="small_logo" href="/">
                <img
                  src="/img/logos/logo_ROBERTET_GROUPE_BLANC.png"
                  id="logoauth"
                  alt="logo"
                />
              </a>
            </div>

            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{ borderRadius: 5 }}>
                <div className="lang-menu">
                  <a href="#fr">
                    <img
                      src="/img/flags/fr_flag.png"
                      className="flagdimension"
                      onClick={onChangeLanguage}
                      alt=""
                    />
                  </a>
                  <a href="#en">
                    <img
                      src="/img/flags/US_flag.png"
                      className="flagdimension"
                      onClick={onChangeLanguage1}
                      alt=""
                    />
                  </a>
                </div>

                <div className="card-body p-5">
                  <h2
                    className="text-uppercase text-center mb-10"
                    id="titreprincipal2">
                    {t("confirm.connexion")}
                  </h2>

                  <form noValidate onSubmit={handleSubmit} id="formLogin">
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="login">
                        <i className="fas fa fa-key"></i>
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        style={{ border: "solid 2px #FFA500" }}
                        placeholder={t("confirm.password1")}
                        id="password"
                        aria-describedby="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="input-group-text"
                        id="password"
                        onClick={togglePassword}>
                        <i className="fas fa fa-eye"></i>
                      </span>
                    </div>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="password">
                        <i className="fas fa fa-key"></i>
                      </span>
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        placeholder={t("confirm.password2")}
                        aria-describedby="token"
                        id="password-confirm"
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                      />
                    </div>
                    <div className="row pt-3">
                      <div className="col-12  text-end">
                        <div className="form-group">
                          <input
                            type="submit"
                            value={t("confirm.submit")}
                            className="btn btn-success"
                            style={{
                              background: "rgba(0, 44, 82, 1)",
                              marginLeft: "auto",
                              width: "auto",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
