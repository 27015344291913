import { useState } from "react";

import { addComment } from "@services/utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default function FormComment(props) {
  const { t } = useTranslation();
  const form_content = props.form_content;

  const { register, handleSubmit } = useForm({});
  const [comment, setComment] = useState("");

  const onSubmit = (data) => {
    Object.assign(data, {
      comment: comment,
      survey_type: props.data.survey_type,
    });

    if (props.data.parent_id !== undefined) {
      Object.assign(data, { parent_id: props.data.parent_id });
    }

    if (props.data.title !== undefined) {
      Object.assign(data, { title: props.data.title });
    }

    addComment(props.data.survey_id, data, form_content);
  };

  const closeCommentForm = () => {
    const comment_form = document.getElementById("commentsForm");
    comment_form.classList.remove("animate__animated", "animate__slideInDown");
    comment_form.classList.add("animate__animated", "animate__slideOutUp");
    setTimeout(() => {
      comment_form.classList.remove("animate__animated", "animate__slideOutUp");
      comment_form.classList.add("d-none");
      form_content.unmount();
    }, 700);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="formComment" className="h-100">
      <div className="row">
        <div className="mb-3 form-group">
          <input
            className="form-control bg-gradient-gray"
            name="title"
            id="id_title"
            value={props.data.title}
            placeholder={t("comment.form.title")}
            required
            {...register("title")}
          />
        </div>
        <SunEditor
          id="react-trumbowyg"
          className=""
          setOptions={{
            height: 200,
            buttonList: [
              [
                "undo",
                "redo",
                "fontSize",
                "formatBlock",
                "bold",
                "underline",
                "italic",
                "strike",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "list",
              ],
            ],
          }}
          onChange={setComment}
          placeholder={t("comment.form.text")}
          name="comment"
        />
      </div>
      <div className="row p-3">
        <div className="col-6">
          <button
            onClick={closeCommentForm}
            className="btn btn-primary p-3 text-uppercase font-16 font-weight-bold w-100 text-white bg-transparent border border-3"
            id="close-comment">
            {t("general.reset")}
          </button>
        </div>
        <div className="col-6">
          <input
            className="btn btn-primary p-3 text-uppercase font-16 font-weight-bold ml-1 w-100  text-white bg-transparent border border-3"
            type="submit"
            value="Envoyer"
            id="id_submit"
          />
        </div>
      </div>
    </form>
  );
}
