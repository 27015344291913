import React from "react";
import { useTranslation } from "react-i18next";

export default function Notfound() {
  const { t } = useTranslation();

  return (
    <div className="erroe_page_wrapper">
      <div className="errow_wrap">
        <div className="container text-center">
          <img src="img/bak_hovers/sad.html" alt="" />
          <div className="error_heading  text-center">
            <h3 className="headline font-danger theme_color_6">404</h3>
          </div>
          <div className="col-md-8 offset-md-2 text-center">
            <p>{t("alert.notfound")}</p>
          </div>
          <div className="error_btn text-center">
            <a className=" default_btn theme_bg_6 " href="index-2.html">
              {t("actions.back")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
