import React from "react";

export default function Header() {
  const open = () => {
    const nav = document.querySelector("nav");
    nav.className = "sidebar dark_sidebar active_sidebar";
  };
  return (
    <div className="row">
      <div className="col-lg-12 p-0 ">
        <div className="header_iner d-flex justify-content-between align-items-center">
          <div className="sidebar_icon d-lg-none">
            <i className="ti-menu" id="openmenu" onClick={open}></i>
          </div>
        </div>
      </div>
    </div>
  );
}
