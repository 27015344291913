import Menu from "../Menu";
import Header from "../Header";
import { useTranslation } from "react-i18next";
import "animate.css";
import Datatables from "../dashboard/Datatables";

function Dashboard() {
  const { t } = useTranslation();

  return (
    <>
      <Menu />
      <div className="main_content_iner">
        <div className="container-fluid p-0">
          <Header />

          <div className="row">
            <div className="col-lg-12">
              <div className="white_card card_height_100">
                <div className="white_card_header">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <span>{t("general.dashboard")}</span>
                    </div>
                  </div>
                </div>
                <div id="datatables">
                  <Datatables />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="mySidenavR" className="d-none">
        <div id="scrollTop"></div>
        <div id="myContent"></div>
      </div>
    </>
  );
}

export default Dashboard;
