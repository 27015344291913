import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useNavigate } from "react-router-dom";
import { addLocale, locale } from "primereact/api";
import React, { useEffect, useState } from "react";
import { FilterMatchMode } from "primereact/api";
import api from "../../services/api";
import { sortSurvey } from "../../services/utils";
import { renderLinkTemplate } from "@services/utils";
import { renderCompletionTemplate } from "@services/utils";
import { renderDeadlineFormatter } from "@services/utils";
import { renderCommentTemplate } from "@services/utils";
import { useTranslation } from "react-i18next";

import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";

export default function Datatables() {
  const [supplierSurveyList, setSupplierSurveyList] = useState([]);
  const [materialSurveyList, setMaterialSurveyList] = useState([]);

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  let lang = i18n.language;

  const getSupplierSurveyList = () => {
    api.supplier_surveys_list().then((response) => {
      setSupplierSurveyList(sortSurvey(response.data));
    });
  };

  if (lang === "fr" || lang === "fr_FR") {
    addLocale("fr", {
      startsWith: "Commence par",
      contains: "Contient",
      notContains: "Ne contient pas",
      endsWith: "Se termine par",
      equals: "Egal à",
      notEquals: "Différent de",
      noFilter: "Aucun filtre",
      lt: "Inférieur à",
      lte: "Inférieur ou égal à",
      gt: "Supérieur à",
      gte: "Supérieur ou égal à",
      emptyMessage: "Aucun résultat.",
    });
    locale("fr");
  }

  const getMaterialSurveyList = () => {
    api
      .material_surveys_list()
      .then((response) => {
        if (response.status !== 204) {
          setMaterialSurveyList(sortSurvey(response.data));
        }
      })
      .catch((error) => {
        console.log("Error is ", error);
      });
  };

  useEffect(() => {
    getSupplierSurveyList();
    getMaterialSurveyList();
  }, []);

  const statusTemplate = (rowData) => {
    return t(rowData.status);
  };

  const typeTemplate = (rowData) => {
    return t(rowData.survey_type);
  };

  const deadlineFormatter = (rowData) => {
    return renderDeadlineFormatter(rowData, lang);
  };

  const linkTemplate = (rowData) => {
    return renderLinkTemplate(rowData, lang, navigate);
  };

  const completionTemplate = (rowData) => {
    return renderCompletionTemplate(rowData);
  };

  const commentTemplate = (rowData) => {
    return renderCommentTemplate(rowData, navigate);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    material_rawcode: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    material_label_en: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    material_label_fr: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  return (
    <>
      {supplierSurveyList != null ? (
        <div className="divdash">
          <div className="titletable">{t("dashboard.title_supplier")}</div>
          <DataTable
            data-testid="supplier_survey"
            dataKey="id"
            responsiveLayout="scroll"
            value={supplierSurveyList}
            size="small"
            filters={filters}
            globalFilterFields={["material_raw_code", "label", "status"]}
            rows={4}>
            <Column
              field={
                lang === "fr" || lang === "fr-FR"
                  ? "survey_label_fr"
                  : "survey_label_en"
              }
              body={linkTemplate}
              header={t("dashboard.label")}
            />
            <Column header="Type" field={typeTemplate} />
            <Column
              field="deadline"
              header={t("dashboard.deadline")}
              className="datatable-deadline"
              body={deadlineFormatter}
            />
            <Column
              field="completion_rate"
              body={completionTemplate}
              className="datatable-completion"
              header={"% " + t("dashboard.completion")}
            />
            <Column
              field={statusTemplate}
              header={t("dashboard.status")}
              style={{ maxWidth: "10%" }}
            />
            <Column
              body={commentTemplate}
              header="Actions"
              className="datatable-actions"
              style={{ minWidth: "20%" }}
            />
          </DataTable>
        </div>
      ) : (
        <div></div>
      )}
      {materialSurveyList != null ? (
        <div className="divdash">
          <div className="titletable">{t("dashboard.title_raw")}</div>
          <DataTable
            data-testid="mpsurvey"
            dataKey="id"
            responsiveLayout="scroll"
            value={materialSurveyList}
            paginator
            size="small"
            filters={filters}
            globalFilterFields={["material_raw_code", "label", "status"]}
            filterDisplay="row"
            rows={5}>
            <Column
              field="material_rawcode"
              header="Code"
              body={linkTemplate}
              filter
              filterPlaceholder={t("dashboard.searchcode")}
            />
            <Column
              field={
                lang === "fr" || lang === "fr-FR"
                  ? "material_label_fr"
                  : "material_label_en"
              }
              body={linkTemplate}
              header={t("dashboard.label")}
              filter
              filterPlaceholder={t("dashboard.searchlabel")}
            />
            <Column
              field="deadline"
              header={t("dashboard.deadline")}
              body={deadlineFormatter}
            />
            <Column
              field="completion_rate"
              body={completionTemplate}
              header={"% " + t("dashboard.completion")}
              style={{ width: "20%", with: "20%" }}
            />
            <Column
              field={statusTemplate}
              header={t("dashboard.status")}
              style={{ maxwidth: "20%", width: "20%" }}
              filter
              filterPlaceholder={t("dashboard.searchstatus")}
            />
            <Column
              body={commentTemplate}
              header="Actions"
              className="datatable-actions"
            />
          </DataTable>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
