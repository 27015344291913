import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "@services/api";

export default function Login() {
  //LANGUAGE
  const { t, i18n } = useTranslation();
  const onChangeLanguage = (e) => {
    i18n.changeLanguage("fr");
  };
  const onChangeLanguage1 = (e) => {
    i18n.changeLanguage("en");
  };

  const [email, setEmail] = useState();
  const [toHide, setHide] = useState();
  const [toShow, setShow] = useState("d-none");
  const [toShowMsg, setShowMsg] = useState("d-none");
  const hideForm = () => {
    setHide("hidden");
    setShow("d-block");
  };

  const showMessage = () => {
    setShowMsg("d-inline");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    await api
      .password_reset({
        email,
      })
      .then((response) => {
        if (response.data.status === "OK") {
          hideForm();
        }
      })
      .catch((error) => {
        if (error.response.data.email) {
          showMessage();
        }

        console.log(error.response);
      });
  };

  return (
    <section className="vh-100" id="connexion">
      <div className="d-flex align-items-center h-100">
        <div className="container h-100 p-5">
          <div
            className="row d-flex  align-items-center h-100 p-2"
            id="container">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6" id="divauth1">
              <a className="small_logo" href="/">
                <img
                  src="/img/logos/logo_ROBERTET_GROUPE_BLANC.png"
                  id="logoauth"
                  alt="logo"
                />
              </a>
            </div>

            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="card" style={{ borderRadius: 5 }}>
                <div className="lang-menu">
                  <a href="#fr">
                    <img
                      src="/img/flags/fr_flag.png"
                      className="flagdimension"
                      onClick={onChangeLanguage}
                      alt=""
                    />
                  </a>
                  <a href="#en">
                    <img
                      src="/img/flags/US_flag.png"
                      className="flagdimension"
                      onClick={onChangeLanguage1}
                      alt=""
                    />
                  </a>
                </div>

                <div className="card-body p-5">
                  <h2
                    className={toHide + " text-uppercase text-center mb-10 "}
                    id="titreprincipal2">
                    {t("reset.connexion")}
                  </h2>
                  <div className={toShow + " message"}>
                    {t("reset.reset_message")}
                  </div>
                  <form
                    noValidate
                    onSubmit={handleSubmit}
                    id="formLogin"
                    className={toHide}>
                    <div className="input-group mb-3">
                      <span className="input-group-text" id="login">
                        <i className="fas fa fa-user"></i>
                      </span>
                      <input
                        type="email"
                        className="form-control"
                        style={{ border: "solid 2px #FFA500" }}
                        placeholder={t("login.email")}
                        id="reset"
                        aria-describedby="login"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="row pt-3">
                      <div className="col-12  text-end">
                        <div className="form-group">
                          <input
                            type="submit"
                            value={t("reset.submit")}
                            className="btn btn-success"
                            style={{
                              background: "rgba(0, 44, 82, 1)",
                              marginLeft: "auto",
                              width: "auto",
                            }}
                          />
                        </div>
                      </div>
                      <div className={toShowMsg + " alert-warning mt-2"}>
                        {t("reset.noemail")}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
