import api from "./api";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import * as Survey from "survey-react";
import swal from "sweetalert";
import { createRoot } from "react-dom/client";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faEdit,
  faEye,
} from "@fortawesome/pro-duotone-svg-icons";
import Comments from "@components/comments/Comments";
import CommentsList from "@components/comments/CommentsList";
import FormComment from "@components/comments/FormComment";
import "moment/locale/fr";
import { BrowserRouter as Router } from "react-router-dom";
//import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import { polyfill } from "seamless-scroll-polyfill";
import Datatables from "@components/dashboard/Datatables";

polyfill();

// INSPIRED by
// CUSTOM UPLOAD :
// https://surveyjs.io/Examples/Library/?id=file-custom-preview&platform=Knockoutjs&theme=modern#content-js

var myCss1 = {
  matrixdynamic: {
    iconAdd: "fa fa-plus",
  },
};

var myCss2 = {
  matrixdynamic: {
    iconRemove: "fa fa-trash",
  },
};

/**
 * If the token is valid, return true, else return false
 * @returns An array of two values, the first being a boolean and the second being a string.
 */

function checkAuth() {
  var token = sessionStorage.getItem("access");
  var expiry = sessionStorage.getItem("expiry");
  var profile_complete = sessionStorage.getItem("profile_complete");

  let auth = false;

  // If token and token expiry greater than date : pass
  if ((token !== undefined) & (new Date(expiry) > Date.now())) {
    return [true, profile_complete];
  }
  // If token and token expiry lower than date, refresh token or send false
  if (
    (token !== undefined && token !== null) &
    (new Date(expiry) < Date.now())
  ) {
    api
      .supplier_info(sessionStorage.getItem("supplier_id"))
      .then((response) => {
        checkAuth();
      });
  }

  return [auth, profile_complete];
}


/**
 * The function takes in an array of objects, sorts the objects by the date in the deadline property,
 * and returns the sorted array
 * @param data - the array of objects that you want to sort
 * @returns the sorted array of survey objects.
 */
function sortSurvey(data) {
  let surveySort = data.sort(
    (a, b) =>
      new Date(...a.deadline.split("/").reverse()) -
      new Date(...b.deadline.split("/").reverse())
  );
  return surveySort;
}


/**
 * It returns a date object that is the current date plus the number of minutes passed in
 * @param minute - The number of minutes to set the cookie for.
 * @returns A date object that is set to the current time plus the number of minutes passed in.
 */
function computeExpiry(minute) {
  var date = new Date();
  date.setTime(date.getTime() + minute * 60 * 1000);
  return date;
}

/* Creating an array of strings. */
async function checkRequiredFields(supplier_id) {
  let count = 0;
  const fields_required = [
    "name",
    "adr1",
    "city",
    "region",
    "country",
    "employees",
    //"holding_part",
    //"holding_name",
    "primary_activity",
    "other_activities",
    //"siren",
    //"siret",
    "zipcode",
    "sales_revenues",
  ];
/* The above code is checking if the supplier has completed their profile. */

  await api
    .supplier_info(supplier_id)
    .then((response) => {
      if (response.status !== 204) {
        fields_required.forEach((field) => {
          response.data[field] == null
            ? (count = count + 1)
            : (count = count + 0);
        });
        if (count === 0) {
          sessionStorage.setItem("profile_complete", true);
        } else {
          sessionStorage.setItem("profile_complete", false);
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return count;
}

/**
 * It stores the access token, refresh token, expiry time, and user information in the session storage
 * @param response - The response from the server
 * @param _type - This is the type of user that is logging in. It can be either a supplier or a
 * customer.
 */

function storeSession(response, _type) {
  sessionStorage.setItem("access", response.data.access);

  if (
    (response.data.refresh !== null) &
    (response.data.refresh !== undefined)
  ) {
    sessionStorage.setItem("refresh", response.data.refresh);
  }

  sessionStorage.setItem("expiry", computeExpiry(120));
  if (_type !== undefined) {
    sessionStorage.setItem(
      "supplier_id",
      JSON.stringify(response.data.supplier_id)
    );
    sessionStorage.setItem(
      "supplier_name",
      JSON.stringify(response.data.supplier_name)
    );
    sessionStorage.setItem(
      "user_email",
      JSON.stringify(response.data.user_email)
    );
  }
}
/**
 * It returns a React component that renders a Bootstrap progress bar with a label
 * @param rowData - The data for the row that is being rendered.
 * @returns A function that returns a JSX element.
 */

function renderCompletionTemplate(rowData) {
  return (
    <div className="row">
      <div className="col-lg-8 p-2">
        <ProgressBar
          now={rowData.completion_rate}
          label={`${rowData.completion_rate}%`}
          variant={
            rowData.completion_rate < 50
              ? rowData.completion_rate < 25
                ? "danger"
                : "warning"
              : "success"
          }
          visuallyHidden
        />
      </div>
      <div className="col-lg-4" style={{ fontSize: "13px" }}>
        {rowData.completion_rate}
        {" %"}
      </div>
    </div>
  );
}
/**
 * It takes in a rowData object and a navigate function, and then it uses the rowData object to
 * determine which survey to fetch from the API, and then it uses the navigate function to navigate to
 * the survey page
 * @param rowData - The data of the row that was clicked.
 * @param navigate - This is the navigation function that you can use to navigate to another page.
 */

async function getRow(rowData, navigate) {
  var _type;
  var data_id;
  let link;

  if (rowData.material_survey_id !== undefined) {
    _type = "material";
    data_id = rowData.material_survey_id;
    link = "/material_survey";
  }

  if (rowData.survey_id !== undefined) {
    _type = "supplier";
    data_id = rowData.survey_id;
    link = "/supplier_survey";
  }

  await api.survey_item(_type, data_id).then((response) => {
    navigate(link, {
      replace: false,
      state: {
        survey: response.data.survey,
        survey_result: response.data.survey_result,
        object: rowData,
      },
    });
  });
}

/**
 * The function takes in an event, rowData, type, and navigate. It prevents the default action of the
 * event, and then calls the getRow function with the rowData and navigate
 * @param e - the event object
 * @param rowData - the data for the row that was clicked
 * @param _type - This is the type of the row. It's used to determine which component to render.
 * @param navigate - This is the function that will be called to navigate to the next page.
 */
function handleClick(e, rowData, _type, navigate) {
  e.preventDefault();
  getRow(rowData, navigate);
}
/**
 * It returns a Link component with a custom className, data-id, to, onClick, and id
 * @param rowData - the data for the row
 * @param lang - the language of the app
 * @param navigate - a function that takes a path as a parameter and navigates to that path
 * @returns A function that returns a Link component.
 */

function renderLinkTemplate(rowData, lang, navigate) {
  var _type;
  var data_id;
  if (rowData.material_survey_id !== undefined) {
    _type = "material";
    data_id = rowData.material_survey_id;
  }
  if (rowData.survey_id !== undefined) {
    _type = "supplier";
    data_id = rowData.supplier_id;
  }
  return (
    <Link
      className="custom-link"
      data-id={data_id}
      to="#"
      onClick={(event) => handleClick(event, rowData, _type, navigate)}
      id={`link-${data_id}`}>
      {selectLang(rowData, _type, lang)}
    </Link>
  );
}
/**
 * It returns the label of a material or supplier, depending on the language
 * @param rowData - The row data object
 * @param _type - The type of the data you want to select.
 * @param lang - The language of the user.
 * @returns the label of the material or supplier depending on the language.
 */

function selectLang(rowData, _type, lang) {
  var label;
  if (_type === "material") {
    if (lang === "fr" || lang === "fr-FR") {
      label = rowData.material_label_fr;
    } else {
      label = rowData.material_label_en;
    }
  }

  if (_type === "supplier") {
    if (lang === "fr" || lang === "fr-FR") {
      label = rowData.survey_label_fr;
    } else {
      label = rowData.survey_label_en;
    }
  }

  return label;
}
/**
 * It takes a rowData object and a language string as parameters, and returns a formatted date string
 * @param rowData - The data for the row that is being rendered.
 * @param lang - The language of the user.
 * @returns A Moment component with the date in the format of "ll" (long date) in the locale of "fr" or
 * "en"
 */

function renderDeadlineFormatter(rowData, lang) {
  var locale;
  if (lang === "fr" || lang === "fr-FR") {
    locale = "fr";
  } else {
    locale = "en";
  }
  return (
    <Moment parse="YYYY-MM-DD" format="ll" locale={locale}>
      {new Date(rowData.deadline)}
    </Moment>
  );
}

/**
 * It takes a file, converts it to a blob, and then converts that blob to a data URL
 * @param newValue - The value of the file input
 * @param container - The container element where the preview will be rendered.
 * @param id - The id of the survey
 * @param _type - The type of file you want to download.
 * @param options - {
 * @returns The file is being returned as a blob.
 */

function updatePreview(newValue, container, id, _type, options) {
  container.innerHTML = "";
  (newValue || []).forEach(function (fileItem) {
    var button = document.createElement("div");
    button.className = "btn sv-btn sv-file__choose-btn sd-btn sd-btn--action";
    button.innerText = "Download " + fileItem.name;
    button.onclick = function () {
      console.log(options);
      api
        .survey_download_file(id, _type, options.content)
        .then(function (response) {
          return response;
        })
        .then(function (blob) {
          console.log(options);
          var reader = new FileReader();
          reader.onload = function (e) {
            options.callback("success", e.target.result);
          };
          reader.readAsDataURL(
            new File([blob], options.fileValue.name, {
              type: options.fileValue.type,
            })
          );
          options.callback("success", URL.createObjectURL(blob));
        });
    };
    container.appendChild(button);
  });
}
/**
 * It prepares the survey for rendering
 * @param lang - The language of the survey.
 * @param location - the location object from the router
 * @param _type - 1 for material survey, 2 for survey
 * @param t - is the translation function
 * @param treatment - the treatment name
 * @returns A survey object.
 */

function prepareSurvey(lang, location, _type, t, treatment) {
  let json = null;
  let id = null;
  const loadata = {};
  const survey_data = location.state.survey_result;
  const survey_id = location.state.object.survey_id;

  if (_type === 1) id = location.state.object.material_survey_id;
  else id = survey_id;
  for (let i in survey_data) {
    delete survey_data[i].score;
    Object.assign(loadata, survey_data[i]);
  }

  json = location.state.survey.surveyData;

  //json = require("../components/surveys/material_survey.json");

  Survey.StylesManager.applyTheme("modern");
  //Add properties to survey
  Survey.JsonObject.metaData.addProperty("tagbox", { name: "type" });

  //change required question Text
  // Survey.surveyLocalization.locales[
  //   Survey.surveyLocalization.defaultLocale
  // ].requiredError = "Question obligatoire";

  Survey.surveyLocalization.locales["fr"].requiredError = "Réponse obligatoire";
  Survey.surveyLocalization.locales["en"].requiredError = "Required answer";

  Survey.Serializer.addProperty("itemvalue", {
    name: "score",
    category: "general",
  });

  Survey.JsonObject.metaData.addProperty("itemvalue", { name: "score:number" });

  const survey = new Survey.Model(json);
  if (lang === "fr-FR" || lang === "fr") survey.locale = "fr";

  survey.css = myCss1;
  survey.css = myCss2;

  //Upload File Trigger
  survey.onUploadFiles.add(function (survey, options) {
    /*Clear File Trigger start*/
    survey = clearFileSurvey(survey, id, t, _type, treatment) 

    /*Upload File start*/
    let formData = new FormData();
    options.files.forEach((file) => {
      formData.append("question", options.name);
      formData.append("file", file, file.name);
      api
        .survey_upload_file(id, _type, file, options, formData)
        .then((resultJson) => {
          options.callback(
            "success",
            options.files.map((file) => {
              return {
                file: file,
                content: resultJson.data.filename,
              };
            })
          );
        })
        .catch((error) => {
          console.log("Error is ", error);
        });
    });
  });
  //download File Trigger
  //NB: Download file event should be before loading data survey
  survey.onDownloadFile.add(function (survey, options) {
    api
      .survey_download_file(id, _type, options.content)
      .then(function (response) {
        //return response.blob();
      })
      .then(function (blob) {
        var reader = new FileReader();
        reader.onload = function (e) {
          options.callback("success", e.target.result);
        };
        reader.readAsDataURL(
          new File([blob], options.fileValue.name, {
            type: options.fileValue.type,
          })
        );
        //options.callback("success", URL.createObjectURL(blob));
      });
  });
  // Desactive required validation on survey.
  //survey.ignoreValidation = true
  survey.checkErrorsMode = "onComplete";
  survey.data = loadata;

  survey.onAfterRenderPage.add(function (survey, options) {
    var status = location.state.object.status;
    if (status === "inreview") {
      survey.mode = "display";
    }

    /// Add save button
    var btn = document.getElementById("btns");
    var _continue = document.getElementById("sv-nav-start"); //.previousSibling;
    var parentDiv = _continue.parentNode;

    parentDiv.insertBefore(btn, _continue);

    if (survey.mode !== "display") {
      btn.classList.remove("d-none");
    }

    try {
      if (survey.mode === "display") {
        // Disable all footer buttons
        var footer_btns = document.getElementsByClassName(
          "sv-btn sv-btn--navigation"
        );

        for (let i = 0; i < footer_btns.length; i++) {
          var input = footer_btns[i];
          if (input !== undefined) {
            input.setAttribute("disabled", true);
            input.disabled = true;
          }
        }
      }

      // Disable complete button if rate under 100
      var complete = document.getElementsByClassName(
        "sv-footer__complete-btn"
      )[0];
      //survey.progressValue < 100 ||
      if (survey.mode === "display") {
        complete.setAttribute("disabled", true);
      } else {
        complete.removeAttribute("disabled");
      }
    } catch (e) {}
  });

  survey.onAfterRenderQuestion.add(function (sender, options) {
    if (options.question.getType() === "file") {
      //Change file title if lang is FR
      if (lang === "fr" || lang === "fr-FR") {
        const fileElement1 = options.htmlElement.getElementsByClassName(
          "sv-btn sv-file__choose-btn"
        )[0];
        const children = fileElement1.children;
        children[0].innerText = "Choisir Fichier";
        const fileElement2 = options.htmlElement.getElementsByClassName(
          "sv-description sv-file__no-file-chosen"
        )[0];
        if (fileElement2) fileElement2.innerText = "Aucun Fichier choisi";
        const fileElement3 = options.htmlElement.getElementsByClassName(
          "sv-btn sv-file__clean-btn"
        )[0];
        if (fileElement3) {
          const children3 = fileElement3.children;
          //children3[0].innerText = "Supprimer fichier";
        }
      }
      var container = document.createElement("div");
      container.className = "my-preview-container";
      var fileElement =
        options.htmlElement.getElementsByClassName("sv_q_file")[0];
      if (!fileElement) {
        fileElement =
          options.htmlElement.getElementsByClassName("sv-file__decorator")[0];
      }
      if (!fileElement) {
        fileElement =
          options.htmlElement.getElementsByClassName("sd-file__decorator")[0];
      }
      fileElement.appendChild(container);
      options.question.onPropertyChanged.add(function (question, options) {
        if (options.name === "value") {
          updatePreview(options.newValue, container, id, _type, options);
        }
      });
      updatePreview(options.question.value, container, id, _type, options);
    }
  });

  survey.onComplete.add(function (survey, options) {
    // SaveSurvey(survey, survey_id, complete, t, _type, treatment)
    SaveSurvey(survey, id, "complete", t, _type, treatment);
  });

  survey.onCurrentPageChanged.add(function (survey, option) {
    SaveSurvey(survey, id, "continue", t, _type, treatment);
  });

  // autosave and change progressbar
  survey.onValueChanged.add(function (survey, option) {
    const progress_value = Math.round(survey.progressValue);
    console.log(survey.progressValue);
    survey.completion_rate = progress_value;
    SaveSurvey(survey, id, "continue", t, _type, treatment);
    const progress_bar = document.getElementById("progress_bar").children[0];
    //Change progressBar color
    progress_bar.className = "";
    progress_value < 50
      ? progress_value < 25
        ? progress_bar.classList.add("progress-bar", "bg-danger")
        : progress_bar.classList.add("progress-bar", "bg-warning")
      : progress_bar.classList.add("progress-bar", "bg-success");
    //element.setAttribute
    progress_bar.setAttribute("aria-valuenow", progress_value);
    progress_bar.setAttribute("style", "width: " + progress_value + "%");

    const completion_text = document.getElementById("completion_text");
    completion_text.innerHTML = progress_value + " %";
  });

  return survey;
}


function clearFileSurvey(survey, id, t, _type, treatment) {
  survey.onClearFiles.add((response, options) => {
    if (options.question.value !== undefined) {
      api
        .survey_delete_file(id, _type, options)
        .then((resultJson) => {
          if (resultJson.status === 200) {
            console.log("--- function ----")
            //CleanSurvey(survey, options)
            SaveSurvey(survey, id, "continue", t, _type, treatment);
          }
        })
        .catch((error) => {
          console.log("Error is ", error);
        });
    }
    options.callback("success");
  });

  return survey
}
function addSurvey(result, survey_id, _type) {
  api
    .survey_result_update(result, survey_id, _type)
    .then((response) => {
      //console.log(response)
    })
    .catch((error) => {
      //console.log('Error is ', error);
    });
}
/**
 * It takes in a bunch of parameters, and returns an array of two items: the first item is an object,
 * and the second item is a number
 * @param data - the data object
 * @param score - the current score of the user
 * @param question - the question object
 * @param item - the question object
 * @param qValue - the value of the question
 * @param choice - the choice object
 * @param treatment - boolean
 * @returns An array of two elements: the first element is an object, the second element is a number.
 */

function choice_treatment(
  data,
  score,
  question,
  item,
  qValue,
  choice,
  treatment
) {
  if (treatment === undefined || treatment === false) {
    if (choice.value === qValue) {
      score += +choice.score;
      if (choice.score !== null) {
        Object.assign(item, { score: choice.score });
      }
    } else {
      score += 0;
    }
  } else {
    if (choice.value === qValue) {
      if (choice.score != null) {
        score += +choice.score;
        Object.assign(item, { score: choice.score });
      } else {
        let num_quest = question.name.replace("question", "");
        let array1 = [96, 101, 115, 123];
        let array2 = [99, 103, 108, 110, 113, 118, 120, 126, 129];
        if (
          array1.indexOf(num_quest) !== -1 &&
          (choice.value === "oui" || choice.value === "Yes")
        ) {
          let prec_quest = `question${num_quest - 1}`;
          if (
            data[prec_quest] === "proactif" ||
            data[prec_quest] === "Proactive approach"
          ) {
            score += 50;
            Object.assign(item, { score: 50 });
          }
          if (
            data[prec_quest] === "obligation" ||
            data[prec_quest] === "Legislation requirement"
          ) {
            score += 40;
            Object.assign(item, { score: 40 });
          }
        } else if (
          array2.indexOf(num_quest) !== -1 &&
          (choice.value === "oui" || choice.value === "Yes")
        ) {
          let prec_quest = `question${num_quest - 1}`;
          if (
            data[prec_quest] === "proactif" ||
            data[prec_quest] === "Proactive approach"
          ) {
            score += 100;
            Object.assign(item, { score: 100 });
          }
          if (
            data[prec_quest] === "obligation" ||
            data[prec_quest] === "Legislation requirement"
          ) {
            score += 90;
            Object.assign(item, { score: 90 });
          }
        } else if (
          num_quest === 106 &&
          (choice.value === "oui" || choice.value === "Yes")
        ) {
          let prec_quest = `question${num_quest - 1}`;
          if (
            data[prec_quest] === "proactif" ||
            data[prec_quest] === "Proactive approach"
          ) {
            score += 75;
            Object.assign(item, { score: 75 });
          }
          if (
            data[prec_quest] === "obligation" ||
            data[prec_quest] === "Legislation requirement"
          ) {
            score += 90;
            Object.assign(item, { score: 65 });
          }
        } else {
          score += 0;
          Object.assign(item, { score: 0 });
        }
      }
    } else {
      score += 0;
    }
  }
  return [item, score];
}
/**
 * It takes a survey, a survey id, a completion status, a translation object, a type, and a treatment,
 * and returns a score and a completion rate
 * @param survey - the survey object
 * @param survey_id - the id of the survey
 * @param complete - if the survey is complete or not
 * @param t - the translation function
 * @param _type - the type of survey (e.g. "pre-test", "post-test", "mid-test")
 * @param treatment - the treatment name
 */

function SaveSurvey(survey, survey_id, complete, t, _type, treatment) {
  var score = 0;
  var data = survey.data;

  var result = {
    survey_result: [],
    completion_rate: null,
    score: null,
  };

  Object.keys(data).forEach(function (qName) {
    var question = survey.getQuestionByName(qName);
    var qValue = data[qName];
    var item = {};
    item[qName] = qValue;
    if (question.choices) {
      question.choices.forEach(function (choice) {
        let names = choice_treatment(
          data,
          score,
          question,
          item,
          qValue,
          choice,
          treatment
        );
        item = names[0];
        score = names[1];
      });
    }
    result.survey_result.push(item);
  });

  result.score = score;

  //Taux de complétion
  let com = survey.getProgress();

  //result.completion_rate = completionTaux.toFixed(3);
  result.completion_rate = com;

  // ADD complete to change STATUS on API SIDE
  if ((com === 100) & (complete !== undefined) & (complete === "complete")) {
    result.complete = true;
  }

  addSurvey(JSON.stringify(result), survey_id, _type);

  if (complete !== "continue") {
    let text = complete === "close" ? `${t("alert.save")}` : `${t("alert.complete")}`;
    
    swal({
      text: text,
      buttons: false,
      timer: 7000,
    });

  }

  if (complete === "close") {
    window.location = "/dashboard";
  }
}

async function getComments(rowData) {
  var survey_id;
  var _type = rowData.survey_type_id;

  if (rowData.survey_type_id === 2) {
    survey_id = rowData.survey_id;
  } else {
    survey_id = rowData.material_survey_id;
  }
  await api.survey_comment_list(survey_id, _type).then((response) => {
    if (response.status !== 204) {
      const container = document.getElementById("myContent");
      const comments_page = createRoot(container);

      comments_page.render(
        <Comments
          comments={response.data}
          survey_id={survey_id}
          survey_type={rowData.survey_type_id}
          comments_page={comments_page}
        />
      );
    }
  });
}

/**
 * `create_root` takes an element id and returns a list root
 * @param element_id - The id of the element that will contain the list.
 * @returns the list_root.
 */
function create_root(element_id) {
  const container = document.getElementById(element_id);
  const list_root = createRoot(container);
  return list_root;
}
/**
 * It takes a survey id and a survey type, and then it updates the comments section of the page with
 * the comments for that survey
 * @param survey_id - The id of the survey
 * @param _type - the type of survey, either "public" or "private"
 */

async function updateComments(survey_id, _type) {
  // commentsContent
  var list_root = create_root("commentsContent");

  await api.survey_comment_list(survey_id, _type).then((response) => {
    if (response.status !== 204) {
      list_root.unmount();
      var new_list_root = create_root("commentsContent");
      new_list_root.render(
        <CommentsList
          comments={response.data}
          survey_id={survey_id}
          survey_type={_type}
        />
      );
    }
  });
}

/**
 * It takes in a rowData object, and then it opens the sidebar and calls the getComments function with
 * the rowData object as an argument
 * @param rowData - The data of the row that was clicked on.
 */
const openSidebar = async (rowData) => {
  const sidebar = document.getElementById("mySidenavR");
  sidebar.classList.add("animate__animated", "animate__slideInRight");
  sidebar.classList.remove("d-none");
  getComments(rowData);
};
/**
 * It removes the animation class that makes the sidebar slide in from the right, and adds the
 * animation class that makes the sidebar slide out to the right. Then, it removes the animation class
 * that makes the sidebar slide out to the right, and adds the class that makes the sidebar hidden.
 * Finally, it unmounts the component that was mounted on the sidebar, and mounts the datatables
 * component on the datatables root
 * @param mounted_element - This is the element that you want to unmount.
 */

function closeSidebar(mounted_element) {
  const sidebar = document.getElementById("mySidenavR");
  sidebar.classList.remove("animate__animated", "animate__slideInRight");
  sidebar.classList.add(
    "animate__animated",
    "animate__slideOutRight",
    "animate__slow"
  );

  setTimeout(() => {
    sidebar.classList.remove("animate__animated", "animate__slideOutRight");
    sidebar.classList.add("d-none");
    mounted_element.unmount();
  }, 1200);

  const datatables = create_root("datatables");
  datatables.render(
    <Router>
      <Datatables />
    </Router>
  );
}
/**
 * It takes in a survey_id, data, and form_content, and then it adds a comment to the survey with the
 * given survey_id
 * @param survey_id - The id of the survey you want to add a comment to.
 * @param data - This is the data that will be sent to the API.
 * @param form_content - The form content that you want to unmount after the comment is added.
 */

function addComment(survey_id, data, form_content) {
  api
    .survey_add_comment(survey_id, data)
    .then((response) => {
      if (response.status !== 204) {
        updateComments(survey_id, data.survey_type);
        form_content.unmount();
      }
    })
    .catch((error) => {
      console.log("Error is ", error);
    });
}
/**
 * It returns a button that opens a sidebar with a list of comments for the row
 * @param rowData - The data for the row that is being rendered.
 * @param navigate - This is the function that will be called when the user clicks on the row.
 * @returns A function that returns a button.
 */

function renderCommentTemplate(rowData, navigate) {
  const setIcon = (rowData) => {
    if (
      rowData.status === "inreview" ||
      rowData.status === "verified" ||
      rowData.status === "archived"
    ) {
      return faEye;
    } else {
      return faEdit;
    }
  };

  return (
    <>
      <button
        className="btn btn-primary ms-1"
        href="#"
        onClick={(event) => getRow(rowData, navigate)}>
        <FontAwesomeIcon icon={setIcon(rowData)} size="lg" />
      </button>
      <button
        className="btn btn-primary ms-1"
        onClick={() => openSidebar(rowData)}>
        <span
          className={rowData.comment_count > 0 ? "fa-layers fa-fw fa-1x" : ""}
          data-count={rowData.comment_count}>
          <FontAwesomeIcon icon={faCommentDots} size="lg" />
        </span>
      </button>
    </>
  );
}
/**
 * It takes in a comment_id, survey_id, and survey_type, and then deletes the comment with the given
 * comment_id
 * @param comment_id - the id of the comment to be deleted
 * @param survey_id - The id of the survey
 * @param survey_type - This is the type of survey. It can be either "survey" or "poll".
 */

function deleteComment(comment_id, survey_id, survey_type) {
  api
    .survey_delete_comment(comment_id)
    .then((response) => {
      if (response.status !== 204) {
        updateComments(survey_id, survey_type);
        //form_content.unmount();
      }
    })
    .catch((error) => {
      console.log("Error is ", error);
    });
}
/**
 * It takes a data object and a target object, and then it creates a form_content object, and then it
 * renders a FormComment component, and then it scrolls to the top of the page, and then it adds some
 * classes to the comment_form element, and then it removes a class from the comment_form element
 * @param data - The data object that will be passed to the FormComment component.
 * @param [target=false] - The target element that was clicked.
 */

function writeComment(data, target = false) {
  if (target) {
    const parent_id = target.currentTarget.getAttribute("data-comment-id");
    const title = target.currentTarget.getAttribute("data-title");
    Object.assign(data, { parent_id: parent_id, title: "Re: " + title });
  }
  const form_content = create_root("formContent");

  form_content.render(<FormComment data={data} form_content={form_content} />);
  const element = document.getElementById("scrollTop");
  element.scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "center",
  });

  const comment_form = document.getElementById("commentsForm");
  comment_form.classList.add(
    "animate__animated",
    "animate__fadeInUp",
    "animate__slower"
  );
  comment_form.classList.remove("d-none");
}
/**
 * It takes a target, gets the comment id from the target, sets the target's innerHTML to "Lu",
 * disables the target, and then calls the API to mark the comment as read
 * @param target - the button that was clicked
 */

function setApiStatus(target) {
  console.log("=== wee");
  const comment_id = target.currentTarget.getAttribute("data-comment-id");
  target.currentTarget.innerHTML = "Lu";
  target.currentTarget.disabled = true;
  api.survey_comment_read(comment_id).then((response) => {
    console.log(response);
  });
}
/**
 * It returns a span element with a class of tag me-1 and the text mark as read if the comment has not
 * been read and the user is not the author of the comment
 * @param comment - the comment object
 * @returns A function that returns a span element.
 */

function setCommentStatus(comment) {
  var user = sessionStorage
    .getItem("user_email")
    .replace('"', "")
    .replace('"', "");
  var user_email = comment.user_email;

  if (comment.read === false && user !== user_email) {
    return (
      <span
        data-comment-id={comment.id}
        onClick={(e) => {
          setApiStatus(e);
        }}
        className="tag me-1">
        mark as read
      </span>
    );
  }

  if (comment.read === true && user !== user_email) {
    return <span className="tag me-1">lu</span>;
  }

  if (user === user_email) {
    return "";
  }
}

export {
  SaveSurvey,
  addSurvey,
  prepareSurvey,
  computeExpiry,
  storeSession,
  sortSurvey,
  checkAuth,
  renderCompletionTemplate,
  renderCommentTemplate,
  renderLinkTemplate,
  renderDeadlineFormatter,
  getRow,
  closeSidebar,
  addComment,
  writeComment,
  deleteComment,
  setCommentStatus,
  setApiStatus,
  checkRequiredFields,
};
