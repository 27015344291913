import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import AuthHeader from '../services/auth-header';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGrid,
  faSquarePollHorizontal,
  faRightFromBracket,
  faBuildingCircleCheck,
} from "@fortawesome/pro-duotone-svg-icons";
import swal from "sweetalert";

function Menu() {
  const name = JSON.parse(sessionStorage.getItem("supplier_name"));
  const email = JSON.parse(sessionStorage.getItem("user_email"));
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };

  const { t } = useTranslation();
  const checkProfile = () => {
    let profile = sessionStorage.getItem("profile_complete");
    if (profile === "false") {
      swal({
        text: `${t("alert.completeProfile")}`,
        buttons: false,
        timer: 3000,
      });
    }
  };
  return (
    <nav className="sidebar dark_sidebar ">
      <div className="usermenu">
        <div>
          <a className="large_logo" href="/dashboard">
            <img
              src="/img/logos/logo_ROBERTET_GROUPE_BLANC.png"
              id="logomenu"
              alt="logo"
            />
          </a>
        </div>
        <div className="sidebar_close_icon d-lg-none">
          <i className="ti-close" />
        </div>
        <div className="title_user">{name}</div>
        <div className="mail_user">{email}</div>
        <hr className="line_user" />
      </div>
      <ul id="sidebar_menu">
        <li>
          <Link to="/dashboard" onClick={checkProfile}>
            <div className="nav_icon_small">
              <FontAwesomeIcon icon={faGrid} size="2x" />
            </div>
            <div className="nav_title">
              <span>{t("general.dashboard")}</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/profile">
            <div className="nav_icon_small">
              <FontAwesomeIcon icon={faBuildingCircleCheck} size="2x" />
            </div>
            <div className="nav_title">
              <span>{t("general.profile")}</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/surveys" onClick={checkProfile}>
            <div className="nav_icon_small">
              <FontAwesomeIcon icon={faSquarePollHorizontal} size="2x" />
            </div>
            <div className="nav_title">
              <span>{t("general.survey")}s</span>
            </div>
          </Link>
        </li>
        <li>
          <Link to="">
            <div className="nav_icon_small">
              <FontAwesomeIcon icon={faRightFromBracket} size="2x" />
            </div>
            <div className="nav_title">
              <span onClick={logout}>{t("login.signout")}</span>
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );
}
export default Menu;
