import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faReply } from "@fortawesome/pro-duotone-svg-icons";
import {
  writeComment,
  setCommentStatus,
  deleteComment,
} from "../../services/utils";
import { useTranslation } from "react-i18next";

export default function Comment(props) {
  const { t } = useTranslation();
  const comment = props.comment;

  const renderChildren = (children) => {
    if (children) {
      return children.map((item) => (
        <Comment
          comment={item}
          key={item.id}
          survey_id={props.survey_id}
          survey_type={props.survey_type}
        />
      ));
    }
  };

  const data = {
    survey_id: props.survey_id,
    survey_type: props.survey_type,
  };

  return (
    <div
      data-date={comment.submit_date}
      className={
        comment.parent_id
          ? "event bg-child"
          : "event border rounded-3 bg-comments"
      }
      id={"c" + comment.id}>
      <div className="comment p-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 text-left">
                <span className="tag user">{comment.user_name}</span>
              </div>
              <div className="col-lg-6">
                <h5 className="p-1">
                  {comment.title} [{comment.id}]
                </h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 text-right">
            {setCommentStatus(comment)}

            <button
              onClick={(e) => writeComment(data, e)}
              data-title={comment.title}
              data-comment-id={comment.id}
              data-surveyid={props.survey_id}
              data-surveytype={props.survey_type}
              data-demo="dddd"
              className="comment_reply_link btn btn-primary"
              title={t("actions.reply")}>
              <FontAwesomeIcon icon={faReply} />
            </button>
            <button
              onClick={(e) =>
                deleteComment(comment.id, props.survey_id, props.survey_type)
              }
              data-title="{{comment.title}}"
              data-comment-id="{comment.id}"
              className="comment_delete_link btn btn-danger"
              title={t("actions.reply")}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 p-3">{parse(comment.comment)}</div>
        </div>
      </div>
      {renderChildren(comment.children)}
    </div>
  );
}
