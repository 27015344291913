import React from "react";
import * as Survey from "survey-react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "@components/Header";
import Menu from "@components/Menu";
import { prepareSurvey } from "@services/utils";
import { SaveSurvey } from "@services/utils";
import ProgressBar from "react-bootstrap/ProgressBar";

import "survey-react/modern.css";

export default function SurveyRawMaterial() {
  //variable initialisation
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const completion = location.state.object.completion_rate;
  let lang = i18n.language;
  const name = JSON.parse(sessionStorage.getItem("supplier_name"));
  const material_survey_id = location.state.object.material_survey_id;
  const survey = prepareSurvey(lang, location, 1, t, true);

  return (
    <>
      <Menu />
      <Header />
      <div className="main_content_iner">
        <div className="row">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-12 pe-4 ps-4 pb-4">
                <div className="white_card">
                  <div className="white_card_header ">
                    <div className="main-title">
                      <span>
                        {t("general.evaluation")} RSE {t("general.raw")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="rowHeader">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="MP-header">
                  <div>
                    <span className="title">{t("general.raw")}</span>
                    {lang === "fr" || lang === "fr-FR" ? (
                      <span className="value">
                        {location.state.object.material_label_fr}
                      </span>
                    ) : (
                      <span className="value">
                        {location.state.object.material_label_en}
                      </span>
                    )}
                  </div>
                  <div>
                    <span className="title">Code MP</span>
                    <span className="value">
                      {location.state.object.material_rawcode}
                    </span>
                  </div>
                  <div>
                    <span className="title">{t("general.supplier")}</span>
                    <span className="value">{name}</span>
                  </div>
                  <div>
                    <span className="title">Progression</span>
                    <div className="row">
                      <div className="col-lg-8 p-2">
                        <ProgressBar
                          id="progress_bar"
                          now={completion}
                          label={`${completion}%`}
                          variant={
                            completion < 50
                              ? completion < 25
                                ? "danger"
                                : "warning"
                              : "success"
                          }
                          visuallyHidden
                        />
                      </div>
                      <div
                        id="completion_text"
                        className="col-lg-4"
                        style={{ fontSize: "13px" }}>
                        {completion} {" %"}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-lg-12 p-4">
                <div className="white_card">
                  <div className="white_card_heder bg-azure bg-azure-dark">
                    <div className="col-lg-12 ">
                      <Survey.Survey model={survey} />
                    </div>
                    <div className="sv-action d-none" id="btns">
                      <div className="sv-action__content">
                        <input
                          type="button"
                          className="sv-btn sv-btn--navigation"
                          onClick={() =>
                            SaveSurvey(
                              survey,
                              material_survey_id,
                              "close",
                              t,
                              1,
                              true
                            )
                          }
                          value={t("actions.saveClose")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="white_card_body"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid"></div>
      </div>
    </>
  );
}
