import axios from "axios";
const baseUrl = process.env.REACT_APP_URL;

/* Adding the access token to the header of the request. */
axios.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem("access");
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Disposition"] = "form-data; filename=toto";
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

/* This is a response interceptor that is used to refresh the access token when it expires. */
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    let refreshToken = sessionStorage.getItem("refresh");
    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      return axios
        .post(`${baseUrl}token/refresh/`, { refresh: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            sessionStorage.setItem("access", res.access);
            console.log("Access token refreshed!");
            return axios(originalRequest);
          } else {
            window.location = "/login";
          }
        });
    }
    return Promise.reject(error);
  }
);
/* A list of functions that are used to make requests to the backend. */

const api = {
  signup: (body) => {
    return axios.post(`${baseUrl}/auth/signup`, body);
  },
  login: (body) => {
    return axios.post(`${baseUrl}token/`, body).then((res) => {
       return res
     }).catch(function (error) {
       return error.response
    });
  },
  password_reset: (body) => {
    return axios.post(`${baseUrl}password_reset/`, body);
  },
  password_confirm: (body) => {
    return axios.post(`${baseUrl}password_confirm/`, body);
  },
  refreshToken: (body) => {
    return axios.post(`${baseUrl}token/refresh/`, body);
  },
  logout: (body) => {
    return axios.delete(`${baseUrl}token/logout/`, body);
  },
  supplier_surveys_list: () => {
    return axios.get(`${baseUrl}supplier_surveys/`);
  },

  supplier_info: (supplier_id) => {
    return axios.get(`${baseUrl}supplier_infos/${supplier_id}/`);
  },
  supplier_info_update: (supplier_id, body) => {
    return axios.put(`${baseUrl}supplier_infos/${supplier_id}/`, body);
  },
  material_surveys_list: () => {
    return axios.get(`${baseUrl}material_surveys/`);
  },
  material_survey_result_update: (result, material_survey_id) => {
    return axios.put(
      `${baseUrl}material_surveys/${material_survey_id}/`,
      result
    );
  },
  survey_upload_file: (id, survey_type, file, options, formData) => {
    return axios.post(
      `${baseUrl}files_surveys/${id}/${survey_type}/`,
      formData
    );
  },
  survey_delete_file: (survey_id, survey_type, options) => {
    let file_list;
    if (options.fileName !== null) {
      file_list = {
        "filename": options.fileName
      }
    } else {
      file_list = []
      options.value.forEach(function (arrayItem) {
        file_list.push({ filename: arrayItem.name })
      });
    }
    let formData = { data: file_list }
    return axios.delete(
      `${baseUrl}files_surveys_delete/${survey_id}/${survey_type}/`,
      formData
    );
  },
  survey_download_file: (
    survey_id,
    survey_type,
    filename,
    file,
    options,
    formData
  ) => {
    return axios.get(
      `${baseUrl}files_surveys/${survey_id}/${survey_type}/${filename}/`,
      formData
    );
  },
  survey_result_update: (result, item_id, item) => {
    let target;
    if (item === "material" || item === 1) {
      target = "material_surveys";
    }
    if (item === "supplier" || item === 2) {
      target = "supplier_surveys";
    }
    return axios.put(`${baseUrl}${target}/${item_id}/`, result);
  },
  survey_item: (item, survey_id) => {
    let target;
    if (item === "material") {
      target = "material_surveys";
    }
    if (item === "supplier") {
      target = "supplier_surveys";
    }

    return axios.get(`${baseUrl}${target}/${survey_id}/`);
  },

  getProtected: () => {
    return axios.get(`${baseUrl}protected_resource`);
  },
  survey_comment_list: (survey_id, survey_type) => {
    return axios.get(`${baseUrl}comments/${survey_id}/${survey_type}/`);
  },
  survey_comment_read: (comment_id) => {
    return axios.post(`${baseUrl}comments/mark_read/${comment_id}/`);
  },
  survey_add_comment: (survey_id, body) => {
    return axios.post(`${baseUrl}comments/${survey_id}/`, body);
  },
  survey_delete_comment: (comment_id) => {
    return axios.delete(`${baseUrl}comments/${comment_id}/`);
  },
};
export default api;
