import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Menu from "@components/Menu";
import Header from "@components/Header";
import api from "@services/api";
import swal from "sweetalert";

function UpdateSupplier() {
  const { t } = useTranslation();
  var d = new Date();
  var date = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();

  //change thousand numbers
  const addSpace = (num) =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`${t("profile.suppliername")} ${t("required")}`),
    adr1: Yup.string().required(`${t("profile.street")} ${t("required")}`),
    city: Yup.string().required(`${t("profile.city")} ${t("required")}`),
    region: Yup.string().required(`${t("profile.region")} ${t("required")}`).nullable(),
    country: Yup.string().required(`${t("profile.country")} ${t("required")}`),
    employees: Yup.string().required(`${t("profile.employees")} ${t("required")}`).nullable(),
    holding_part: Yup.string().required(`${t("profile.member")} ${t("required")}`),
    //holding_name: Yup.string().required(`${t("ifyes")} ${t("required")}`),
    primary_activity: Yup.string().required(
      `${t("profile.mainActivity")} ${t("required")}`
    ),
    other_activities: Yup.string().required(
      `${t("profile.otherActivity")} ${t("required")}`
    ).nullable(),
    // siren: Yup.string().required(`${t("siren")} ${t("required")}`),
    // siret: Yup.string().required(`${t("siret")} ${t("required")}`),
    siren: Yup.string().nullable(),
    siret: Yup.string().nullable(),
    zipcode: Yup.string().required(`${t("profile.zipcode")} ${t("required")}`),
    sales_revenues: Yup.string().required(`${t("profile.salesRevenues")} ${t("required")}`).nullable(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const watchHoldingPart = watch("holding_part");

  const onSubmit = (data) => {
		data.employees = parseInt(data.employees.replace(/ /g, ""));
		data.sales_revenues = data.sales_revenues.replace(/ /g, ""); //.replace(" ", "");
		api.supplier_info_update(supplier_id, data).then((supplier) => {
			if (supplier) {
				swal({
					text: `${t("alert.alertupdate")}`,
					//icon:"success",
					buttons: false,
					timer: 4000,
				}).then(() => {
					sessionStorage.setItem("profile_complete", true);
					window.location.href = "/dashboard";
				});
			} else {
				swal({
					text: `${t("alert.failed")}`,
					//icon: "error",
					buttons: false,
					timer: 4000,
				});
			}
		});
		// supplierService
		//   .supplier_infos_update(supplier_id, data)
		//   .then((supplier) => {});
	};
  const supplier_id = sessionStorage.getItem("supplier_id");
  const [supplier, setSupplier] = useState({});
  const [holding_part, setHoldingPart] = useState();
  const [employees, setEmployees] = useState();
  const [revenue, setRevenue] = useState();
  const changeRevenue = (event) => {
    setRevenue(addSpace(removeNonNumeric(event.target.value)));
  };
  const changeEmployees = (event) => {
    setEmployees(addSpace(removeNonNumeric(event.target.value)));
  };
  useEffect(() => {
    api.supplier_info(supplier_id).then((user) => {
      const fields = [
        "name",
        "adr1",
        "adr2",
        "city",
        "region",
        "country",
        "employees",
        "holding_part",
        "holding_name",
        "primary_activity",
        "other_activities",
        "siren",
        "siret",
        "zipcode",
        "sales_revenues",
        "factory_address",
        "hq_adress",
        "invoicing_address",
      ];
      fields.forEach((field) => {
        setValue(field, user.data[field]);
      });
      setSupplier(user.data);
      setEmployees(addSpace(removeNonNumeric(user.data["employees"])));
      setRevenue(addSpace(removeNonNumeric(user.data["sales_revenues"])));
      setHoldingPart(user.data["holding_part"]);
    });
    // get user and set form fields
    // supplierService.supplier_infos_read(supplier_id).then((user) => {

    // });
  }, [setValue, supplier_id]);

  const testFunction = () => {
    console.log(watchHoldingPart);
    if (watchHoldingPart === true) {
      return (
        <>
          <Form.Group className="form-group col-lg-6">
            <Form.Label> {t("profile.ifyes")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("profile.ifyes")}
              className={`form-control ${
                errors.holding_name ? "is-invalid" : ""
              }`}
              required
              {...register("holding_name")}
            />
            <div className="invalid-feedback">
              {errors.holding_name?.message}
            </div>
          </Form.Group>
        </>
      );
    }
  };

  return (
    <>
      <Menu />
      <div className="main_content_iner">
        <div className="container-fluid p-0">
          <Header />
          <div className="row">
            <div className="col-lg-12">
              <div className="white_card card_height_100 mb_30">
                <div className="white_card_header">
                  <div className="box_header m-0">
                    <div className="main-title">
                      <span>{t("profile.general")}</span>
                    </div>
                  </div>
                </div>
                <div className="divprofile mt-5">
                  <form onSubmit={handleSubmit(onSubmit)} id="formProfile">
                    <div className="" id="rowbtn">
                      <button type="submit" className="btn" id="btn_add">
                        {t("actions.save")}
                      </button>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <fieldset id="frsfieldset">
                          <legend>{t("profile.information")}</legend>
                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="inputDate"
                                className="form-label col-sm-10 col-form-label">
                                {" "}
                                Date{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputDate"
                                name="date"
                                disabled
                                value={date}
                              />
                            </div>
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="name"
                                className="form-label col-form-label">
                                {t("profile.supplierName")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="name"
                                  type="text"
                                  {...register("name")}
                                  className={`form-control ${
                                    errors.name ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.name?.message}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="primary_activity"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.mainActivity")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="primary_activity"
                                  type="text"
                                  {...register("primary_activity")}
                                  className={`form-control ${
                                    errors.primary_activity ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.primary_activity?.message}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="other_activities"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.otherActivity")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="other_activities"
                                  type="text"
                                  {...register("other_activities")}
                                  className={`form-control ${
                                    errors.other_activities ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.other_activities?.message}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-lg-6 pt-4">
                              <Form.Check
                                type="switch"
                                label={t("profile.isMember")}
                                onClick={() => setHoldingPart(!holding_part)}
                                bsSwitchPrefix="form-switch  form-check-lg"
                                {...register("holding_part")}
                              />
                            </div>
                            {watchHoldingPart && testFunction()}
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-lg-6" id="entreprise1">
                        <fieldset id="frsfieldset">
                          <legend>{t("profile.siege")}</legend>
                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="adr1"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.street")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="adr1"
                                  type="text"
                                  {...register("adr1")}
                                  className={`form-control ${
                                    errors.adr1 ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.adr1?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="adr2"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.suppAddress")}
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="adr2"
                                  type="text"
                                  {...register("adr2")}
                                  className={`form-control ${
                                    errors.adr2 ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.adr2?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="zipcode"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.zipcode")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="zipcode"
                                  type="text"
                                  {...register("zipcode")}
                                  className={`form-control ${
                                    errors.zipcode ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.zipcode?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="city"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.city")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="city"
                                  type="text"
                                  {...register("city")}
                                  className={`form-control ${
                                    errors.city ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.city?.message}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="region"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.region")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="region"
                                  type="text"
                                  {...register("region")}
                                  className={`form-control ${
                                    errors.region ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.region?.message}
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="country"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.country")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="country"
                                  type="text"
                                  {...register("country")}
                                  className={`form-control ${
                                    errors.country ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.country?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-lg-6" id="entreprise2">
                        <fieldset id="frsfieldset">
                          <legend>{t("profile.billing")}</legend>
                          <div className="row">
                            <div className="form-group col-lg-12">
                              <label
                                htmlFor="invoicing_address"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.billingAdress")}
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="invoicing_address"
                                  type="text"
                                  {...register("invoicing_address")}
                                  className={`form-control ${
                                    errors.invoicing_address ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.invoicing_address?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-12">
                              <label
                                htmlFor="factory_address"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.factory")}
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="factory_address"
                                  type="text"
                                  {...register("factory_address")}
                                  className={`form-control ${
                                    errors.factory_address ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.factory_address?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-12">
                              <label
                                htmlFor="hq_adress"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.office")}
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="hq_adress"
                                  type="text"
                                  {...register("hq_adress")}
                                  className={`form-control ${
                                    errors.hq_adress ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.hq_adress?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div className="col-lg-6" id="entreprise3">
                        <fieldset id="frsfieldset">
                          <legend>{t("profile.size")}</legend>
                          <div className="row">
                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="sales_revenues"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.revenue")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  value={revenue}
                                  name="sales_revenues"
                                  type="text"
                                  {...register("sales_revenues")}
                                  className={`form-control ${
                                    errors.sales_revenues ? "is-invalid" : ""
                                  }`}
                                  onInput={changeRevenue}
                                />
                                <div className="invalid-feedback">
                                  {errors.sales_revenues?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="employees"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.employees")}
                                <span className="required">*</span>
                              </label>
                              <div className="col-sm-12">
                                <input
                                  value={employees}
                                  name="employees"
                                  type="text"
                                  {...register("employees")}
                                  className={`form-control ${
                                    errors.employees ? "is-invalid" : ""
                                  }`}
                                  onInput={changeEmployees}
                                />
                                <div className="invalid-feedback">
                                  {errors.employees?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="siren"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.siren")}
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="siren"
                                  type="text"
                                  {...register("siren")}
                                  className={`form-control ${
                                    errors.siren ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.siren?.message}
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-lg-6">
                              <label
                                htmlFor="siret"
                                className="form-label col-sm-12 col-form-label">
                                {t("profile.siret")}
                                
                              </label>
                              <div className="col-sm-12">
                                <input
                                  name="siret"
                                  type="text"
                                  {...register("siret")}
                                  className={`form-control ${
                                    errors.siret ? "is-invalid" : ""
                                  }`}
                                />
                                <div className="invalid-feedback">
                                  {errors.siret?.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UpdateSupplier;
