import { closeSidebar } from "../../services/utils";
import CommentsList from "./CommentsList";
import { faBookmark } from "@fortawesome/pro-solid-svg-icons";
import { writeComment } from "../../services/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket,
  faPenLine,
} from "@fortawesome/pro-duotone-svg-icons";
import "../../../public/css/timeline.css";
import "../../../public/css/comments.css";
import { useTranslation } from "react-i18next";

export default function Comments(props) {
  const { t } = useTranslation();
  const comments = props.comments;
  const comments_page = props.comments_page;

  const data = {
    survey_id: props.survey_id,
    survey_type: props.survey_type,
  };

  return (
    <>
      <div className="container-fluid h-100" id="topScroll">
        <div className="content">
          <div className="row">
            <div className="col-lg-4 text-white p-4  analytics-nav-title">
              <FontAwesomeIcon
                icon={faBookmark}
                size="lg"
                className="i-button"
              />
              <span className="text-white">{t("comment.correspondence")}s</span>
            </div>
            <div className="col-lg-8">
              <div className="text-center" id="commentsButton">
                <button
                  onClick={() => writeComment(data)}
                  className="btn btn-secondary  btn-lg ms-1"
                  id="write-comment"
                  title={t("comment.write")}>
                  <FontAwesomeIcon
                    icon={faPenLine}
                    size="lg"
                    className="i-button"
                  />
                </button>
                <button
                  onClick={(event) => closeSidebar(comments_page)}
                  href="#"
                  className="btn btn-secondary btn-lg ms-1"
                  id="write-comment-close"
                  data-bs-placement="bottom"
                  data-bs-toggle="tooltip"
                  title={t("comment.back")}>
                  <FontAwesomeIcon icon={faRightFromBracket} size="lg" />
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="d-none" id="commentsForm">
                <div id="formContent"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 comments_list">
              <div id="commentsContent" className="p-2">
                <CommentsList
                  comments={comments}
                  survey_id={data.survey_id}
                  survey_type={data.survey_type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
